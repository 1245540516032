import { CustomerAuthService } from '../services/customer-auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: CustomerAuthService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isAuthenticated = await this.authService.isAuthenticated();
    const isMtmProvided= await this.authService.isMtmProvided();
    if (isAuthenticated) {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate(['/customer/redeem/offers-list']);
      return false;
    }
  }
}

//   this.router.navigate(['/customer/redeem/payment-success']);