<div class="loading-overlay">
    <div class="loading-content">
        <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <p class="mt-3">Please wait...</p>
    </div>
</div>
