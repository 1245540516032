import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';
import {faEye, faEyeSlash, faLock, faUser} from '@fortawesome/free-solid-svg-icons'
import { AuthService } from 'src/app/services/auth.service';
import {User} from 'src/app/models/user';
import {AlertifyService} from "../../services/alertify.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  faLock=faLock;
  loading: boolean;
  user: User;
  loginForm=new FormGroup({
    email:new FormControl(''),
    password:new FormControl(''),
  })

  constructor(private authService: AuthService, private router:Router, private notify: AlertifyService) {
    this.loading = false;
    this.user = {} as User;

    this.notify.showError('Sample Error Message');
  }

  ngOnInit(){

  }

  public signIn(): void {
    this.loading = true;
    this.authService.signIn(this.user)
      .then(() => {
        this.router.navigate(['/candidate']);
        this.loading = false;
      }).catch(() => {
      this.loading = false;
    });
  }

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  protected readonly faUser = faUser;
}
function jwt_decode(jwttoken: any) {
  throw new Error('Function not implemented.');
}

