import {Injectable} from '@angular/core';
import * as alertifyjs from "alertifyjs";

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }

  showSuccess(message: string) {
    alertifyjs
      .set('notifier', 'position', 'top-right');
    alertifyjs.success(message);
  }

  showError(message: string) {
   alertifyjs
     .set('notifier', 'position', 'top-right');
   alertifyjs
     .error(message);
  }

  showWarning(message: string) {
    alertifyjs
      .set('notifier', 'position', 'top-right');
    alertifyjs
      .warning(message);
  }

  showNotification() {
   // alertifyjs.notify('sample', 'success', 5, function(){  console.log('dismissed'); });
  }

  showConfirmDialog(options: {}, successCallback: () => void, errorCallback: () => void) {
    alertifyjs
        .confirm(
            "Are you sure you want to logout?",
            successCallback(),
            errorCallback())
        .set(options)
  }

  showConfirmRemove(options: {}, successCallback: () => void, errorCallback: () => void) {
    alertifyjs
        .confirm(
            "Are you sure you want to remove this package?",
            successCallback,
            errorCallback
            )
        .set(options)
  }
}
