import { CustomerAuthService } from '../services/customer-auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MTMGuard implements CanActivate {
  constructor(private authService: CustomerAuthService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    //localStorage.removeItem('customerDetails');
    const isMtmProvided= await this.authService.isMtmProvided();
    if (isMtmProvided) {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate(['/customer/redeem/check-availability']);
      return false;
    }
  }
}