import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  showLoading() {
    this.isLoadingSubject.next(true);
    console.log('Is Loading:', this.isLoading$)
  }

  hideLoading() {
    this.isLoadingSubject.next(false);
  }
  constructor() { }
}
