import { Injectable } from '@angular/core';
import {   ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,} from '@angular/router';
  import { CustomerAuthService } from '../services/customer-auth.service';
@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router,private authService: CustomerAuthService) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    var isToPay:boolean=false
   // debugger;
    const isAuthenticated = await this.authService.isAuthenticated();
    const paymentStart=localStorage.getItem('paymentStart');
    if(paymentStart){
      isToPay=JSON.parse(paymentStart);
    }
    if (isToPay==true && isAuthenticated==true) {
        return true;
    } else {
      this.router.navigate(['/customer/redeem/check-availability']);
      return false; 
    }
  }
}
