<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">
      <img alt="app-logo-colorful.svg" class="app-logo mb-3" height="43" ngSrc="/assets/logo/lenovo-logo.png"
           width="202">
      <div class="mb-4">
        <h3 class="welcome-title">Welcome Back! asdfasd</h3>
        <p class="welcome-message">Sign in to Seek Now.</p>
      </div>

      <div class="sign-in-options">
        <button class="btn sign-in-option google" type="button" title="Sign in with Google account.">
          <img class="icon" [ngSrc]="'/assets/auth/login-options/google-logo.png'" alt="google-logo" width="24"
               height="18">
          <span>Sign in with Google</span>
        </button>
        <button class="btn sign-in-option apple" type="button" title="Sign in with Apple account.">
          <img class="icon" [ngSrc]="'/assets/auth/login-options/apple-logo.png'" alt="apple-logo" width="24"
               height="18">
          <span>Sign in with Apple</span>
        </button>
        <button class="btn sign-in-option microsoft" type="button" title="Sign in with Microsoft account.">
          <img class="icon" [ngSrc]="'/assets/auth/login-options/microsoft-logo.png'" alt="microsoft-logo" width="24"
               height="18">
          <span>Sign in with Microsoft</span>
        </button>
      </div>
      <form action="">
        <div class="form-group mb-2">
          <label for="email" class="form-label">Work Email:</label>
          <div class="input-group">
            <span class="input-group-text icon-preview">
              <fa-icon [icon]="faUser"></fa-icon>
            </span>
            <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email"
                   class="form-control" autofocus="autofocus" autocomplete="none" [value]="''">
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="password" class="form-label">Password:</label>
          <div class="input-group">
            <span class="input-group-text icon-preview">
              <fa-icon [icon]="faLock"></fa-icon>
            </span>
            <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password"
                   #password="ngModel" [(ngModel)]="user.password" class="form-control" value="  ">

            <span class="input-group-text icon-preview show-hide-password"
                  (click)="user.showPassword = !user.showPassword">
              <fa-icon [icon]="user.showPassword ? faEye : faEyeSlash"></fa-icon>
            </span>
          </div>
        </div>
        <div>
          <button type="button" (click)="signIn()" class="btn login-btn" [disabled]="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Sign in
          </button>
        </div>
      </form>

      <div class="create-and-forgot-pw-wrap">
        <p class="link forgot-password-link">
          Forgot your password?
          <a routerLink="/forgot-password">Reset Now.</a>
        </p>

        <p class="link create-new-account-link">
          New Here? <br>
          Let’s
          <a routerLink="/signup"> create an account</a>
          for you.
        </p>
      </div>
    </div>
  </div>
</div>
