import { Observable,BehaviorSubject, of, throwError, map, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import  { Amplify,Auth} from  'aws-amplify'
import { environment } from 'src/environments/environment.development';
import { User,ConfirmModel} from 'src/app/models/user';
import { HttpClient, HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { Country, Currency, Language, TimeZone } from '../models/comboModels';
import { Guid } from 'guid-typescript';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticationSubject: BehaviorSubject<any>;
  private accesstoken :string="";
  private idtoken :string="";
    TimeZones:Array<TimeZone> =[];
    Id: string = Guid.EMPTY;
   confirmModel:ConfirmModel={} as ConfirmModel;
   tokenData:any;

  constructor(private router: Router, private http:HttpClient) {

    Amplify.configure({
          Auth:environment.cognito
    });
     this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

    adminSignUp(user:User):Observable<User> {
      return (this.http.post(environment.adminResourceUrl+"account/Register", user).pipe(
        map(resp => {
          const userData:any = resp;
          return userData;
        })
      ));
    }

    registerCandidate(user:User): Observable<User> {
      return this.http.post<any>(environment.adminResourceUrl+"account/Accounts", user)
        .pipe(map(resp => {
          this.confirmModel.userName=resp.resultSet[1];
          this.confirmModel.userId=resp.resultSet[2];
          return resp;
        }));
    }


        erroHandler(error: HttpErrorResponse) {
          return throwError(error.message || 'server Error');
        }
      
    
    public confirmSignUp(confirmModel: ConfirmModel): Promise<any> {
      return Auth.confirmSignUp(confirmModel.userName, confirmModel.verificationCode);
    }
  
    public getUser(): Promise<any> {
      return Auth.currentUserInfo();
    }
  
    public updateUser(user: User): Promise<any> {
      return Auth.currentUserPoolUser()
      .then((cognitoUser: any) => {
        return Auth.updateUserAttributes(cognitoUser, user);
      });
    }
  
     public getSessionToken() {
      Auth.configure({
        oauth: AuthService
        })
        Auth.currentAuthenticatedUser()
        .then(user => console.log(user))
        .catch(err => console.log(err))
      }

      public isAuthenticated(): Promise<boolean> {
        if (this.authenticationSubject.value) {
          return Promise.resolve(true);
        } else {
          return this.getUser()
          .then((user: any) => {
            if (user) {
              return true;
            } else {
              return false;
            }
          }).catch(() => {
            return false;
          });
        }
      }

      public signOut(): Promise<any> {
        return Auth.signOut()
        .then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('idtoken');
          localStorage.removeItem("userId");
          this.authenticationSubject.next(false);
        });
      }
    
     
    
      public signIn(user: User): Promise<any> {
        return Auth.signIn(user.email, user.password)
        .then((user) => {
         this.getSessionToken();
         this.accesstoken=user.getSignInUserSession().getAccessToken().getJwtToken();
         this.idtoken=user.getSignInUserSession().getIdToken().getJwtToken();
         this.setToken();
          this.authenticationSubject.next(true);
        });
      }

      setToken(): void {
    localStorage.clear();
    localStorage.setItem('token', this.accesstoken);
    localStorage.setItem('idtoken', this.idtoken);
    this.tokenData= jwt_decode(this.idtoken);
    localStorage.setItem("userId",this.tokenData['custom:UserId']);
    localStorage.setItem("tenantId",this.tokenData['custom:TenantId']);
  }

  

  getToken(): string | null {
    return localStorage.getItem('token');
  }
}
  

