import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthService {

  constructor() { }

  public isAuthenticated(): Promise<boolean> {
    const token= localStorage.getItem("accessToken");
    if (token) {
      return Promise.resolve(true);
      
    } else {
      return Promise.resolve(false);
    }
  }
  public isMtmProvided(): Promise<boolean> {
    const mtmNumber= localStorage.getItem("mtmNumber");
    if (mtmNumber) {
      return Promise.resolve(true);
      
    } else {
      return Promise.resolve(false);
    }
  }
}
