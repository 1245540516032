import {trigger, transition, style, animate, query, state} from "@angular/animations";

export const comeOrGo = trigger("comeOrGo", [
  state(
    "*",
    style({
      opacity: 1,
      transform: "translateX(0)",
      height: "*",
      paddingLeft: "*",
      paddingRight: "*",
      paddingTop: "*",
      paddingBottom: "*"
    })
  ),
  state(
    "void",
    style({
      opacity: 0,
      transform: "translateX(-100%)",
      height: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    })
  ),
  transition("void => *", [animate("800ms ease-in")]),
  transition("* => void", [animate("800ms ease-out")])
]);
