import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {User} from 'src/app/models/user';
import {Country, Currency, Language, TimeZone} from 'src/app/models/comboModels';
import {comeOrGo} from "./animation";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {faAngleLeft, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [comeOrGo]
})
export class SignUpComponent implements OnInit {

  protected readonly faAngleLeft = faAngleLeft;

  loading: boolean = false;
  isConfirm: boolean = false;
  user: User = {} as User;
  errorMessage: string = '';
  currentFormStep: number = 1;
  submitted = false;
  step1Completed=false;
  step2Completed=false;

  isAgreedToConditions: boolean = false;

  //signUpUser: UserRegistration = new UserRegistration("", "", "", "", "");


  constructor(private router: Router, private authService: AuthService, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  public signUp(): void {
    //this.user.userName = this.user.userName;
    this.authService.adminSignUp(this.user).subscribe((data) => {
      debugger;
      let result = data;
      this.isConfirm = true;
      this.loading = false;
    });
  }


  submitRegistrationForm(registrationForm:any) {
    this.submitted = true;
    if (registrationForm.valid) {
    this.user = registrationForm.value;
    this.authService.registerCandidate(this.user)
      .subscribe(
        (data:any) => {
          if(data.errors && data.errors.length>0)
            this.errorMessage=data.errors[0].description;
          else
          this.setValues(data);
        }
      );
    }
  }


  setValues(data: any) {
    this.user = data;
    this.router.navigate(['/confirm']);

  }


  goBackToPreviousSteps() {
    this.currentFormStep--;
    if (this.currentFormStep >= 3) {
      console.log("This is the first step");
      this.currentFormStep = 1;
    }
  }

  openNextStep(form:any) {
    if(this.isValidFirstStep(form) && (this.currentFormStep==1 || 
      (this.isValidSecondStep(form) && (this.currentFormStep==2) ||
      (this.isValidThirdStep(form) && this.currentFormStep==3))))
    {
    this.currentFormStep = Math.min(this.currentFormStep + 1, 3);
    }
  }
   isValidFirstStep(form:any)
  {
    return (form.controls.companyAddress.valid && form.controls.firstName.valid && 
      form.controls.lastName.valid && form.controls.companyAddress.valid &&
       form.controls.phoneNumber.valid);
  }

  isValidSecondStep(form:any)
  {
    return (form.controls.email.valid && form.controls.userName.valid && 
            form.controls.password.valid && form.controls.isAcceptedTerms.valid);
  }

  isValidThirdStep(form:any)
  {
    return (form.controls.countryId.valid && form.controls.currencyId.valid && 
            form.controls.languageId.valid && form.controls.timeZoneId.valid);
  }
  openPreviousStep() {
    this.currentFormStep = Math.max(this.currentFormStep - 1, 1);
  }


  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
}

