import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss']
})
export class AccountConfirmComponent implements OnInit{

  validated: boolean = false;
  loading: boolean = true;
  verificationCode:string="";
  
  constructor( private router:Router,private authService:AuthService){
   
    
  }
ngOnInit(): void {
  this.loading=false;
    
}

  public confirmEmail(): void {
  this.validated=this.verificationCode!=null;
    this.authService.confirmModel.verificationCode=this.verificationCode;
    this.authService.confirmSignUp(this.authService.confirmModel)
      .then(() => {
        this.router.navigate(['/login']);
      }).catch(() => {

    });
  }
}
