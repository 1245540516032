<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">

      <img class="app-logo mb-3" [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt="app-logo-colorful.svg" height="43"
           width="202">
      <div class="mb-4">
        <h3 class="welcome-title">Forgot Password</h3>
        <p class="welcome-message">
          Submit your email here to reset the password. You will get an reset confirmation to continue.
        </p>
      </div>

      <form action="" method="">
        <div class="form-group mb-2">
          <label for="email">Work Email:</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="fa fa-lock"></i>
            </span>
            <input type="email" id="email" class="form-control" placeholder="Email Address" name=""/>
          </div>
        </div>
        <div>
          <button type="button" class="btn login-btn">
            Reset Password
          </button>
        </div>
      </form>

      <div>
        <p class="link">
          Do you remember your password?
          <a [routerLink]="'/login'" class="fw-medium">Login Now</a>
        </p>
      </div>
    </div>
  </div>
</div>
