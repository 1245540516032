<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">
      <img class="app-logo mb-3" [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt="app-logo-colorful.svg" height="43" width="202">
      <div class="mb-4">
        <h3 class="welcome-title">Account Confirmation!</h3>
        <p class="welcome-message">Let's confirm your account. Check your email and enter the confirmation code below.</p>
      </div>

      <form action="">
        <div class="form-group mb-2">
          <label for="confirm-code" class="form-label">Confirmation Code:</label>
          <input id="confirm-code" type="text" name="verificationCode" class="form-control" [(ngModel)] ="verificationCode" placeholder="Enter confirmation code here">
        </div>
        <div>
          <button type="button" (click)="confirmEmail()" class="btn login-btn" [disabled]="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading && validated"></span>
            Sign in
          </button>
        </div>
      </form>

      <div class="create-and-forgot-pw-wrap">
        <p class="link forgot-password-link">
          Already have an account?
          <a routerLink="/login">Login Now.</a>
        </p>
      </div>
    </div>
  </div>
</div>
